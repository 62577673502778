.App {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.clippy-section {
  justify-content: center;
  align-items: center;
  z-index: 5;
}

.clippy-div {
  position: fixed;
  top: 0px;
}

.portrait-photo {
  display: block;
  max-width: 200px;
  width: 200px;
  height: auto;
}

.project-photo {
  display: block;
  max-width: 200px;
  width: auto;
  height: auto;
}

.maxWidth100{
  max-width: 100px;
}

.root-photo {
  margin: 1.5rem;
  max-width: 250px;
  width: auto;
  height: auto;
}

.dialup {
  position: relative;
}

.margin-25 {
  margin: 25px;
}

.dialup > div {
  position: absolute;
  height: 100%;
  width: 100%;
  background: #fff;
  top: 0;
  left: 0;
  animation-name: hello;
  animation-duration: 2s; /* Adjust duration as needed */
  animation-fill-mode: forwards;
  animation-delay: 0.5s;
  animation-timing-function: cubic-bezier(0.01, 1, 1, 0.01);
}

.maxWidth40{
  max-width: 100px;
}

.dialup img {
  max-width: 100%;
}

 /* Varied animations */
/* @keyframes hello {
  0%, 33% {
    height: 100%;
    top: 0;
  }
  34% {
    height: 66.67%;
    top: 33.33%;
  }
  34%, 67% {
    height: 66.67%;
    top: 33.33%;
  }
  68% {
    height: 33.33%;
    top: 66.67%;
  }
  100% {
    height: 0;
    top: 100%;
  }
} */

/* reveals in thirds */
/* @keyframes hello {
  0%, 33% {
    height: 66.67%;
    top: 33.33%;
  }
  34%, 67% {
    height: 33.33%;
    top: 66.67%;
  }
  68%, 100% {
    height: 0%;
    top: 100%;
  }
} */

/* smooth top down */
@keyframes hello {
  0%, 10% { height: 90%; top: 10%; }
  11%, 20% { height: 80%; top: 20%; }
  21%, 30% { height: 70%; top: 30%; }
  31%, 40% { height: 60%; top: 40%; }
  41%, 50% { height: 50%; top: 50%; }
  51%, 60% { height: 40%; top: 60%; }
  61%, 70% { height: 30%; top: 70%; }
  71%, 80% { height: 20%; top: 80%; }
  81%, 90% { height: 10%; top: 90%; }
  91%, 100% { height: 0%; top: 100%; }
}

.Project-list-item {
  margin: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.Project-list-text-wrapper {
  width: 100%;
  padding: 1rem
}

.cursor-pointer {
  cursor: pointer;
}

.projectListWrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
  border: 1px solid black;
  width: 50rem;
}

.projectListImageWrapper {
  margin: 1rem;
}

.thin-border {
  border: 1px solid black;
}


.Project-list-item-title {
  font-weight: bold;
}

.Project-list-item-description {
  margin-left: 5px;
  margin-top: 2px;
}

.Project-list-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 50%;
}

.gridWrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(100px, auto);
}

.gridItem {
  grid-column: 1;
  grid-row: 1/2;
}



.backButton {
  position: fixed;
  top: 0;
  left: 0;
  margin: 0.5rem;
}

.Nav-Bar {
  position: fixed;
  top: 0;
  margin: 1rem;
}

.Center-div{
  display: flex;
  justify-content: center;
}

.progressive-render-container {
  width: 100%;
  height: 400px; /* Adjust as needed */
  overflow: hidden;
  position: relative;
}

.progressive-render-image {
  width: 100%;
  height: 100%;
  position: absolute;
}


.About-div{
  display: flex;
  justify-content: center;
  align-items: center;
}

.Projects-div{
  display: flex;
  justify-content: center;
  align-items: center;
}

.Projects-text{
  margin: 5px;
  font-weight: bold;
}


.Statement-div{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 15rem;
  margin-right: 15rem;
}

.About-text{
  padding-left: 25px;
  margin: 5px;
}

.About-text-bold{
  padding-left: 25px;
  margin: 5px;
  font-weight: bold;
}

.About-name{
  margin: 25px;
  font-size: 16px;
}

.full-page{
  display: grid;
  height: 100vh;
  background-color: #fff;
}

.App-header {
  margin-top: -210px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.links-container {
  padding: 1rem;
  color: #5b238f;
}

.links-container-nav {
  padding: 1rem;
  color: #5b238f;
  font-size: 11px;
}


@keyframes blink {
  50% {
    border-color: transparent;
  }
}

.blinking-cursor {
  border-right: 1px solid black;
  animation: blink 1.1s step-start infinite;
}